.wizardRoot {
    height: 100vh;
    background-color: #2b2b2c;
}
.wizardRoot .stepRoot {
    padding: 20px;
}

.wizardRoot .ant-card {
    width: 240px;
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    border-color: #646464;
}

.wizardRoot .ant-card.select {
    width: 240px;
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    border: solid 2px #f06c23;
}

.wizardRoot .ant-card .ant-card-body {
    background-color: #2b2b2c;
}

.wizardRoot .ant-card.select .ant-card-body {
    background-color: #f06c23;
}

.wizardRoot .ant-card.select .ant-card-body .ant-typography {
    color: white;
}

.WizardPage {
    background-color: #2b2b2c;
}

.wizardRoot .WizardPage .toggle {
    margin-top: 15px;
    background-color: buttonface;
}

.wizardRoot .WizardPage .toggle.ant-switch-checked {
    background-color: #f06c23;
}

.wizardRoot .WizardPage .tooltip.tooltipIcon {
    display: inline-flex;
    font-size: 18px;
    margin-left: 5px;
    color: #989898;
}

.wizardRoot .WizardPage .settingFooter {
    margin-top: 10px;
    font-size: 12px;
    color: #7e7e7e;
    width: 240px;
}

.wizardRoot .WizardPage .tooltip.tooltipIcon:hover {
    color: #f06c23;
}

.wizardRoot .WizardPage .bottom {
    margin-top: 40px;
    font-size: 18px;
    color: #989898;
}

.wizardRoot .WizardPage .footerButtons {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:10px;
}

.wizardRoot .WizardPage .footerButtons h1{
    margin:0px 50px 0px 50px !important;
}


.wizardRoot .WizardPage .footerButtons button {
    height: 50px;
    font-weight: bold;
    /*border: 1px #1f1f1f solid;*/
    border: 0;
    color: #a6a6a6;
    background-color: #3c3f41;
    margin: 2px;
}

.wizardRoot .WizardPage .footerButtons button.Mui-disabled {
    background-color: #2b2b2c;
    color: #2b2b2c;
}

.wizardRoot .WizardPage .footerButtons button:hover {
    background-color: #f06c23;
    color: white;
}



.wizardRoot .WizardPage .footerButtons button .text {
    width: 150px;
    text-transform: none;
}

.wizardRoot .WizardPage .footerButtons button.prev {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.wizardRoot .WizardPage .footerButtons button.next {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}