.editorRoot {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.editorSpinRoot {
    position: fixed;
    /*pointer-events: none;*/
    /*pointer-events: none;*/
}

.editorSpinRoot .editorSpin {
    width: 100vw;
    height: 100vh;
    user-select: none;
}

.editorSpinRoot .spinBg {
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.playConsoleRoot {
    z-index: 11;
    position:  fixed;
}

.playConsoleRoot .bg {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
}