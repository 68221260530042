.hierarchyRoot {
    height: 100%;
    width: 100%;
    text-align: left;
    background-color: #1f1f1f;
}

.hierarchyRoot .treeRoot {
    height: 100%;
}

.hierarchyRoot .row {
    font-size: 14px;
    cursor: default;
    user-select: none;
    align-items: center;
    white-space: nowrap;
    position: relative;
    display: flex;
}

.hierarchyRoot .row i {
    margin-right: 6px;
    display: flex;
    align-items: center;;
}

.hierarchyRoot .row button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;
    width: 14px;
}

.hierarchyRoot .row .spacer {
    width: 14px;
}

.hierarchyRoot .row-contents {
    font-size: 13px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 12px;
    width: 100%;
    border: 2px solid transparent;
    border-radius: 4px;
}

.hierarchyRoot .row-contents input {
    display: block;
    width: 100%;
    flex: 1;
    border: none;
    box-shadow: inset 0 0 2px 0 rgba(0,0,0,0.2);
    margin: 0;
    margin: -4px;
    padding-left: 4px;
    margin-right: 1px;
    height: 100%;
    outline: none;

}

.hierarchyRoot .isSelected.selectTab .row-contents{
    background: #606060;
    color: white;
}

.hierarchyRoot .isSelected .row-contents{
    background: #383838;
    color: white;
}

.hierarchyRoot .isSelectedStart .row-contents {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.hierarchyRoot .isSelectedEnd .row-contents {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}


.hierarchyRoot .row first-of-type {
    background: green;
}

.hierarchyRoot .isOverFolder .row-contents {
    background: darkcyan;
}


.hierarchyRoot .isOverFolder.selectTab .row-contents {
    background: darkcyan;
}

.hierarchyRoot .preview {
    animation: fade-in 500ms;
    opacity: 0.5;
}

.hierarchyRoot .selected-count {
    font-size: 11px;
    font-weight: bold;
    background: cornflowerblue;
    color: white;
    border-radius: 3px;
    display: inline-flex;
    padding: 4px;
    left: 0;
    top: 0;
    position: absolute;

    min-width: 20px;
    min-height: 20px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.04);
}

.hierarchyRoot .buttons button {
    display: block;
    margin-bottom: 12px;
}

