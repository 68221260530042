.assetItem {
    padding: 2px;
    text-align: center;
    position: relative;
}

.assetItem.select {
    background-color: #2b3b85;
    /*background-color: #1f1f20;*/
}

.assetItem .image {
    /*-webkit-user-drag: none;*/
}

.assetItem .ant-ribbon {
    opacity: 0.6;
    font-size: 2px;
}

.assetItem .nameContainer {
    font-size: 13px;
    position: absolute;
    word-wrap: break-word;
    vertical-align: middle;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
}