.PropertyViewRoot {
    text-align: left;
    user-select: none;
    height: 100%;
    /*min-width: 300px;*/
    overflow: auto;
    background-color: #1f1f1f;
}


.PropertyViewRoot .body {
    /*margin: 10px;*/
    width: 100%;
}

.componentBox {
    /*padding: 5px;*/
    border: 1px solid #39393a;
    box-sizing: border-box;
}

.componentBox .componentNameContainer {
    /*background-color: #2a2a2a;*/
    min-height: 32px;
}

.componentBox .componentNameContainer:hover {
    background-color: #323232;
}

.componentBox .componentBodyContainer {
    padding: 5px;
}

.componentBox .componentBodyContainer .fold {
    margin-left: 10px;
}

.componentBox .componentBodyContainer .fold:hover {
    background-color: #323232;
}


.valueContainer {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}

.valueContainer .valueLabel {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
}

.valueContainer .inputRef  {
    /*color: rgba(127, 255, 0, 0.39);*/
    /*border-color: #d3b837;*/
}

.valueContainer .inputRef:focus  {
    /*color: rgba(127, 255, 0, 0.39);*/
    /*border-color: #d3b837;*/
}

.valueContainer .inputRef:hover  {
    /*color: rgba(127, 255, 0, 0.39);*/
    /*border-color: #d3b837;*/
}

.valueContainer .inputRef::placeholder  {
    /*color: rgba(127, 255, 0, 0.39);*/
}

.valueContainer .inputRef.goRef::placeholder  {
    /*color: rgba(255, 89, 0, 0.39);*/
}

.valueContainer .inputRef.compRef::placeholder  {
    /*color: rgba(255, 0, 0, 0.39);*/
}

.valueContainer .valueBody {
}