.editorLayoutRoot {
    flex-grow: 1;
    position: relative;
}

.playConsoleRoot .lineStyle{

}

.editorLayoutRoot .gameViewHead {
    height: 100px;
}