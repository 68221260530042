.gameViewRoot {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#gameViewRoot.gameViewRoot .sceneTabs {
    height: 32px;
}

#gameViewRoot.gameViewRoot .sceneTabs .ant-tabs-nav {
    margin-bottom: 0;
}


#gameViewRoot.gameViewRoot .sceneTabs .ant-tabs-tab {
    padding-bottom: 3px;
    padding-top: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 16px;
    background-color: #262626;
    color: #a29e9f;
}

#gameViewRoot.gameViewRoot .sceneTabs .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #39393a;
}

#gameViewRoot.gameViewRoot .sceneTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
}

#gameViewRoot.gameViewRoot .sceneTabs .ant-tabs-nav-add {
    background-color: #262626;
}


#gameViewRoot.gameViewRoot .ant-tabs-content-holder {
    border-bottom: 1px rgb(51, 51, 51) solid;
}

.gameView {
    width: 100%;
    height: calc(100% - 66px);
    overflow: hidden;
}

.gameView .sceneName {
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 50%;
}

.gameView .mouseInfo {
    position: absolute;
    right: 5px;
    top: 65px;
    color: chartreuse;
    opacity: 50%;
}

.gameView .zoomScale {
    position:absolute;
    font-size: 16px;
    right: 5px;
    top: 85px;
    opacity: 50%;
    color: #43de24;
}

#gameViewRoot.gameViewRoot .toolBox {
    width: 100%;
    height: 32px;
    background-color: #1f1f1f;
    display: flex;
}

#gameViewRoot.gameViewRoot .toolBox .buttonContainer {
    display: flex;
    font-size: 19px;
    /*flex-grow: 1;*/
    flex: 1 1 0px
}

#gameViewRoot.gameViewRoot .toolBox .buttonContainer .button {
    width: 40px;
    background-color: #39393a;
    border-radius: 3px;
    margin: 2px;
}

#gameViewRoot.gameViewRoot .toolBox .buttonContainer .button:hover {
    background-color: #656565;
}

#gameViewRoot.gameViewRoot .toolBox .buttonContainer .button2 {
    width: 40px;
    background-color: #39393a;
    border-radius: 3px;
    margin: 2px;
}

#gameViewRoot.gameViewRoot .toolBox .buttonContainer .button2:hover {
    background-color: #424242;
}

#gameViewRoot.gameViewRoot .toolBox .buttonContainer .button.active {
    background-color: #46607c;
}